<template>
  <div class="user-profile--Pauses-list">
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <pause-history-table :pauses="pauses" :loading="loading" />
      </v-col>
    </v-row>

    <assign-new-program v-model="addNewPrgoram" @refresh-list="fetchPauses" />
  </div>
</template>

<script>
import PauseHistoryTable from '../components/PauseHistoryTable'

import { mdiPlus } from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'UserPauseList',

  components: {
    PauseHistoryTable,
  },

  data() {
    return {
      loading: false,
      addNewPrgoram: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  created() {
    this.fetchPauses()
  },

  destroyed() {
    this.clearPauses()
  },

  computed: {
    ...mapState({
      pauses: (state) => state.userProfile.pauses,
    }),
  },

  methods: {
    ...mapActions({ getPauses: 'userProfile/getPauses' }),
    ...mapMutations({ clearPauses: 'userProfile/clearPauses' }),

    async fetchPauses() {
      this.loading = true

      await this.getPauses(this.$route.params.id)

      await this.$sleep()

      this.loading = false
    },
  },
}
</script>
