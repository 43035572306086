<template>
  <div class="user-pause--history-table">
    <v-simple-table>
      <thead>
        <tr>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Justification</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="hasPauses && !loading">
          <tr v-for="pause in pauses" :key="pause.id">
            <td>
              {{ pause.start_date | formatIntakeDate($date) }}
            </td>
            <td>
              {{ pause.end_date | formatIntakeDate($date) }}
            </td>
            <td style="width: 40%">
              {{ pause.justification }}
            </td>
            <td>
              <v-chip :color="pause.active | membershipStatusColor" dark>
                {{ pause.active ? 'Active' : 'Expired' }}
              </v-chip>
            </td>
          </tr>
        </template>
      </tbody>
      <table-skeleton-loader v-if="loading" column="6" row />
    </v-simple-table>

    <no-list v-if="!hasPauses && !loading" details="No Programs Enrolled" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import dayjs from 'dayjs'
import NoList from '@/components/elements/NoList'
import { mdiCheckCircle } from '@mdi/js'

export default {
  name: 'PauseHistoryTable',

  components: {
    NoList,
    TableSkeletonLoader,
  },

  data() {
    return {
      icons: {
        check: mdiCheckCircle,
      },
    }
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    pauses: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  computed: {
    hasPauses() {
      return !!this.pauses.length
    },
  },

  filters: {
    formatIntakeDate(value, date) {
      if (!value) {
        return ''
      }

      return dayjs.utc(value).format('MMM DD, YYYY')
    },
    membershipStatusColor(status) {
      if (status === true) return 'green'
      if (status === false) return 'red'

      return 'orange'
    },
  },

  methods: {},
}
</script>
